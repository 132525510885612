import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Kosmetyka mężczyzn | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/oferta/kosmetyka-mezczyzn/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const KosmetykaMezczyzn = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_4)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Kosmetyka mężczyzn</h1>

        <p style={{ margin: '0' }}>
          Zadbany mężczyzna jest świadomy jak należy dbać o swoją skórę twarzy.
        </p>

        <p>
          Nasz gabinet oferuje następujące zabiegi dla mężczyzn:
        </p>

        <ul>
          <li>zabiegi pielęgnacyjne i odmładzające, które redukują zmęczenie i powodują, że twarz wygląda świeżo</li>
          <li>zabieg oczyszczenia manualnego skóry</li>
          <li>pilingi: mikrodermabrazja, piling kawitacyjny, pilingi chemiczne</li>
          <li>zabiegi dla skór wrażliwych i naczyniowych; sonoforeza, ultradźwięki</li>
          <li>Dermapen - mezoterapia mikroigłowa</li>
        </ul>

        <p>Odmładzanie:</p>

        <ul>
          <li>fale radiowe</li>
          <li>depilacja woskiem</li>
          <li>dobór kosmetyków do pielęgnacji domowej</li>
        </ul>

        <h2>Cena: od 120 zł.</h2>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_4: file(relativePath: {regex: "/hero\/4.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
  }
`;

export default KosmetykaMezczyzn;
